import React, {  useState, useEffect } from 'react';
import {  ArrowForwardIcon } from '@chakra-ui/icons';
import {
	Box,
	Heading,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Flex
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
// import {  useSelector } from 'react-redux';
import _ from 'lodash';
import { Editor } from "@tinymce/tinymce-react";
import { FileRemove } from '../../utils/components/FileRemove';
import FileUpload from '../../utils/components/FileUpload';
import { editBlog, patchBlog } from './redux/adminAPI';
import { useParams } from 'react-router';
require('dotenv').config()



export const EditBlog = () => {
	// const { user } = useSelector((state) => state.auth);

	const { id } = useParams();

	const [initialValues, setinitialValues] = useState({
		title:'',
		author:'',
		body:'',
		file:'',
		id:0,
		img:'',
		thumb:''
	});

	const [isDownloads, setisDownloads] = useState(false);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required().label('Title'),
		author: Yup.string().required().label('Author'),
		body: Yup.string()
			.required()
			.min('50', 'Message should be a minimum of 50 characters')
			.label('Body'),
        file: id>0 ? false : Yup.mixed().required('A file is required')
	});

	const submitFunction2 = (values, setStatus, setErrors) => {
		const formData = new FormData();
		Object.keys(values).forEach((key) => {
			formData.append(key, values[key]);
		});
		return patchBlog(formData)
		.then((res) => {
			setStatus(res.data.message);
			alert('Blog saved successfully.');
		})
		.catch((err) => {
			console.log(err);
		});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		//enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values, { setStatus, setErrors }) => {
			console.log(values);
			return submitFunction2(values, setStatus, setErrors);
		},
	});

	const handleChange = (content, editor) => {
		//setMessage(content);
		setinitialValues({body:content})
	}

	const handleFileChange = (files, field) => {
		console.log(files);
		if (!_.isEmpty(files)) {
			return formik.setFieldValue(field, files[0]);
		}
		return formik.setErrors({
			[field]:
				'Invalid file format, please only upload one of the allowed formats',
		});
	};

	// const downloadFile = (fileName) => {
	// 	formik.setStatus(null);
	// 	return getFile({ fileName, student_id: user.student_id })
	// 		.then((res) => {
	// 			const data = res.data; // or res.blob() if using blob responses

	// 			const url = window.URL.createObjectURL(
	// 				new Blob([data], {
	// 					type: res.headers['content-type'],
	// 				})
	// 			);

	// 			const link = document.createElement('a');
	// 			link.href = url;
	// 			link.setAttribute(
	// 				'download',
	// 				`${user.student_id}-${user.applying_for}-${fileName}`
	// 			);
	// 			document.body.appendChild(link);
	// 			link.click();
	// 			link.parentNode.removeChild(link);
	// 		})
	// 		.catch((err) => {
	// 			formik.setStatus(err.response.data.message);
	// 		});
	// };

	useEffect(()=>{
		if(id>0) {
		return editBlog({id:id})
			.then((res) => {
				let a= res.data;
				setinitialValues({
					id:a.id,
					title:a.title,
					author:a.author,
					body:a.body,
					img:a.img,
					thumb:a.thumb

				});
				formik.setValues({
					id:a.id,
					title:a.title,
					author:a.author,
					body:a.body,
					img:a.img,
					thumb:a.thumb

				})
				setisDownloads(true)
			})
			.catch((err) => {
				console.log(err);
			});
		}
		else {
			setinitialValues({
				title:'',
				author:'',
				body:'',
				file:'',
				id:0
			});
		}
	},[])

	return (
		<Box px={{ base: '2', md: '10' }}>
			<form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
				<Heading as='h4' size='xl' mb='10'>
					Edit Blog
				</Heading>
				<Box mb='10'>
					
					<FormControl
						id='title'
						mb='5'
						isInvalid={formik.errors.title}
						isRequired
					>
						<FormLabel>Title</FormLabel>
						<Input type='text' {...formik.getFieldProps('title')} />
						<FormErrorMessage>{formik.errors.title}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='author'
						mb='5'
						isInvalid={formik.errors.author}
						isRequired
					>
						<FormLabel>Author</FormLabel>
						<Input type='text' {...formik.getFieldProps('author')} />
						<FormErrorMessage>{formik.errors.author}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={formik.errors.file}>
						<Flex alignItems='center' justifyContent='space-between' mb='2'>
							<FormLabel>file:</FormLabel>
							{isDownloads && (
								<Link href={process.env.REACT_APP_BASE_API_URL+'/'+initialValues.img} target="_BLANK"><img src={process.env.REACT_APP_BASE_API_URL+'/'+initialValues.img} style={{height:50,width:50}} /></Link>
							)}
						</Flex>
						<FileUpload
							handleChange={handleFileChange}
							isMultiple={false}
							name='file'
							accept='file/png, file/jpg, file/jpeg'
						/>
						<FileRemove formik={formik} field='file' />
						<FormErrorMessage>{formik.errors.file}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='title'
						mb='5'
						isInvalid={formik.errors.title}
						isRequired
					>
						<FormLabel>Body</FormLabel>
						<Editor
							value={initialValues.body}
						    //{...formik.getFieldProps('body')}
							apiKey="1z7kpmr8b56boigvcd9k9wjr2vytpqsxiw9fulbpss3qhs3l" 
						    init={{
						      height: 200,
						      menubar: false,
							  plugins: 'quickbars image table lists media autoresize help',
  							  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncentre alignright alignjustify | indent outdent | bullist numlist | image | table',
  							  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
						    }}
						    onEditorChange={handleChange}
						  />
						<FormErrorMessage>{formik.errors.body}</FormErrorMessage>
					</FormControl>
				</Box>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Flex>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
				<br />
				<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
