import axios from 'axios';

export const getEnquiries = (params) => {
	return axios.get('/enquiry', { params });
};

export const getUsers = (params) => {
	return axios.get('/auth/users', { params });
};

export const getUser = (id) => {
	return axios.get(`/auth/users/${id}`);
};

export const getBlogs = (params) => {
	return axios.get('/blogs/grid', { params });
};

export const postBlog = (params) => {
	return axios.post('/blogs', params);
};

export const editBlog = (params) => {
	return axios.get('/blogs/edit/'+params.id, { params });
};

export const patchBlog = (params) => {
	return axios.patch('/blogs', params);
};

export const getComments = (params) => {
	return axios.get('/blogs/comments/grid', { params });
};

export const patchComment = (params) => {
	return axios.patch('/blogs/comments', params);
};

export const getErpRegistration = (params) => {
	return axios.get('/registererp/grid', { params });
};

export const getBroucherEnquries = (params) => {
	return axios.get('/brochure/grid', { params });
};
