import React, { useState } from 'react';
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Select,
	Input,
	Button,
	CircularProgress,
	Center,
	Flex,
} from '@chakra-ui/react';

export default function StepTwo({
	backFunction,
	formik,
	cities,
	states,
	handleStateChange,
	cityLoading,
}) {
	const [educationSelect, setEducationSelect] = useState('');
	const handleEducationSelectChange = (e) => {
		let education = e.target.value;
		setEducationSelect(education);
		if (education === 'other') {
			return formik.setFieldValue('education', '');
		}
		return formik.setFieldValue('education', education);
	};
	const handleEducationInputChange = (e) => {
		let education = e.target.value;
		return formik.setFieldValue('education', education);
	};
	return (
		<>
			<FormControl
				id='education'
				mb='5'
				isInvalid={formik.errors.education}
				isRequired
			>
				<FormLabel>Education</FormLabel>
				<Select
					name='education'
					placeholder='Select option'
					onChange={(e) => handleEducationSelectChange(e)}
					mb='2'
				>
					<option value='hsc'>HSC</option>
					<option value='final_year'>Final Year</option>
					<option value='graduate'>Graduate</option>
					<option value='graduate_working'>Graduate and Working</option>
					<option value='other'>Other</option>
				</Select>
				{educationSelect === 'other' && (
					<FormControl id='education' isInvalid={formik.errors.education}>
						<Input
							type='text'
							onChange={(e) => handleEducationInputChange(e)}
							placeholder='Specify Education'
						/>
					</FormControl>
				)}
				<FormErrorMessage>{formik.errors.education}</FormErrorMessage>
			</FormControl>
			<FormControl
				id='applying_for'
				mb='5'
				isInvalid={formik.errors.applying_for}
				isRequired
			>
				<FormLabel>Applying For</FormLabel>
				<Select
					placeholder='Select option'
					{...formik.getFieldProps('applying_for')}
				>
					<option value='pgdm'>PGDM</option>
					<option value='pgdme'>PGDM (Executive)</option>
					<option value='pgdmerp'>PGDM-ERP</option>
					<option value='pgpba'>PGP Business Analytics</option>
					<option value='pgpib'>PGP Investment Banking</option>
				</Select>
				<FormErrorMessage>{formik.errors.applying_for}</FormErrorMessage>
			</FormControl>
			<FormControl id='state' mb='5' isInvalid={formik.errors.state} isRequired>
				<FormLabel>state</FormLabel>
				<Select
					placeholder='Select option'
					{...formik.getFieldProps('state')}
					onChange={(e) => handleStateChange(e.target.value)}
				>
					{states.map((state, index) => {
						return (
							<option key={index} value={state.state}>
								{state.state}
							</option>
						);
					})}
				</Select>
				<FormErrorMessage>{formik.errors.state}</FormErrorMessage>
			</FormControl>
			<FormControl
				id='city'
				mb='5'
				isInvalid={formik.errors.city}
				position='relative'
				isRequired
			>
				{cityLoading && (
					<Center
						position='absolute'
						width='100%'
						height='100%'
						top='0'
						left='0'
						zIndex='2'
						backgroundColor='rgba(255,255,255,0.6)'
					>
						<CircularProgress size='35px' isIndeterminate />
					</Center>
				)}
				<FormLabel>City</FormLabel>
				<Select
					placeholder='Select option'
					{...formik.getFieldProps('city')}
					disabled={cities ? (cities.length > 0 ? false : true) : true}
				>
					{cities &&
						cities.map((city, index) => {
							return (
								<option key={index} value={city.city}>
									{city.city}
								</option>
							);
						})}
				</Select>
				<FormErrorMessage>{formik.errors.city}</FormErrorMessage>
			</FormControl>
			<FormControl
				id='reference_number'
				mb='5'
				isInvalid={formik.errors.reference_number}
			>
				<FormLabel>Reference number</FormLabel>
				<Input type='text' {...formik.getFieldProps('reference_number')} />
				<FormErrorMessage>{formik.errors.reference_number}</FormErrorMessage>
			</FormControl>
			<Flex justifyContent='space-between'>
				<Button
					type='button'
					colorScheme='gray'
					width={{ base: '100%', md: 'auto' }}
					onClick={() => backFunction()}
				>
					Back
				</Button>
				<Button
					type='submit'
					colorScheme='blue'
					width={{ base: '100%', md: 'auto' }}
					loadingText='Register'
					spinnerPlacement='end'
					isLoading={formik.isSubmitting}
				>
					Register
				</Button>
			</Flex>
		</>
	);
}
