import {
	Box,
	Button,
	Flex,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { getBroucherEnquries } from './redux/adminAPI';
import { Link } from 'react-router-dom';

export const BroucherEnquiry = () => {
	const [next, setnext] = useState(2);
	const [prev, setprev] = useState(null);
	const [list, setList] = useState(null);
	const [showMessage, setshowMessage] = useState(null);

	const getData = async (page) => {
		await getBroucherEnquries({ limit: 10, page })
			.then((res) => {
				setList(res.data.list);
				setprev(res.data.prev);
				setnext(res.data.next);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleShowMessage = (id) => {
		if (showMessage === id) {
			return setshowMessage(null);
		}
		return setshowMessage(id);
	};

	useEffect(() => {
		const getInitialData = async () => {
			await getBroucherEnquries({ limit: 10, page: 1 })
				.then((res) => {
					setList(res.data.list);
					setprev(res.data.prev);
					setnext(res.data.next);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getInitialData();
	}, []);

	return (
		<Box>
			<Heading size='xl' mb='6'>
				PGDM ERP Broucher Enquiry
			</Heading>
			<Box overflow='auto' mb='4'>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Sr</Th>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Mobile</Th>
							<Th>Created At</Th>
						</Tr>
					</Thead>
					<Tbody>
						{list && list.length > 0 ? (
							list.map((eq, index) => {
								return (
									<Fragment key={eq.id}>
										<Tr
										>
											<Td borderBottom='none'>{index + 1}</Td>
											<Td borderBottom='none'>{eq.name}</Td>
											<Td borderBottom='none'>{eq.email}</Td>
											<Td borderBottom='none'>{eq.mobile}</Td>
											<Td borderBottom='none'>
												{format(parseISO(eq.createdAt), 'dd-MM-yyyy')}
											</Td>
											
										</Tr>
									</Fragment>
								);
							})
						) : (
							<Tr>
								<Td colSpan={5}>No list</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Flex justifyContent='flex-end'>
				<Button
					disabled={!prev}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedLeft='lg'
					onClick={() => getData(prev)}
				>
					Previous
				</Button>
				<Button as={Text} colorScheme='blue' rounded='none'>
					{next ? next - 1 : prev ? prev + 1 : 1}
				</Button>
				<Button
					disabled={!next}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedRight='lg'
					onClick={() => getData(next)}
				>
					Next
				</Button>
			</Flex>
		</Box>
	);
};
